import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesDiesel: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Diesel (Treasure) guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_diesel_tr.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Diesel (Treasure) guide & review</h1>
          <h2>
            A guide & review for Diesel (Treasure) in NIKKE: Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>15/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Diesel (Treasure)" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon-tier"
                  slug="diesel"
                  enablePopover
                  tierID={25}
                />
              </div>
              <p>
                Diesel - one of the most wholesome personalities in the game -
                has been given an upgrade thanks to this new collectibles
                system. Back in the old days, she used to be one of the better
                tanks, and had niche usage against bosses for players with
                incomplete rosters. Slowly, she was powercreeped out of
                everywhere she was used in, even from Tribe Tower. But now, she
                has been given another chance. An upgrade to decide whether or
                not she is part of this revival series by Shift Up, or does she
                still lack that special something which other Meta Nikkes
                possess. Let's take a look together.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                If Laplace deals more damage with her Favorite Item version,
                then Diesel is the same... except instead of improving her own
                DPS, she improves not only the DPS of her allies, and has also
                become a better tank!, she has become a better tank and buffer!
                Since Nikke is a mostly DPS race, tankers like Diesel will have
                hard time fitting in the Meta Teams sadly. But to be fair, if we
                consider Crown as the god Defender right now, then Diesel should
                be the Fake Crown, for some units at least. Diesel's new major
                buff abilities comes from two things: Firstly, the ability to
                buff Pierce Damage, which she does so substantially! Secondly,
                her ability to reload magazines of all allies, from which MGs
                can greatly benefit from. Hence, piercers like Snow White,
                Maxwell, Red Hood and Alice are the major benefitters, along
                with MG units, especially Guillotine, for whom Diesel can tank
                the world for to allow her to survive at even 1 HP!
              </p>
              <h5>Should I get the Favorite Item for this unit?</h5>
              <p>
                Diesel is a good choice to go for when it comes to upgrading
                Favorite Items. However, we feel like her use is too niche. Her
                main meta use may be in either Snow White teams as an upgrade of
                Helm, or as a tanker for units like Guillotine who have special
                prowess in niche conditions (electric-weak bosses with a core in
                Guillotine's case). She can also be effectively used to help
                Guillotine survive in Elysion Tribe Tower against Bosses (only
                Bosses since in campaign-like stages, Diesel is likely to get
                one-shot due to her constant taunt without a shield). If any of
                the following screams useful enough to you, you should
                definitely aim for Diesel's treasure. Otherwise, it is better to
                focus Doll upgrade materials on more meta DPS units like Red
                Hood or Alice, or better favorite items (which is only Laplace
                for now, or Exia if you aim to strengthen your Electric element
                roster). If you decide to get her treasure, we recommend
                stopping at Phase 2.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <h5>Sweeter Strawberries [Phase 1]</h5>
              <StaticImage
                src="../../../images/nikke/reviews/diesel_tr_1.webp"
                alt="Kit"
              />
              <p>
                This phase changes Diesel's Skill 2. It is unlocked once you
                have Diesel's bond at lv 30, SR Doll MG version lv 15 equipped
                on her and you finish some very heartwarming missions to unlock
                Diesel Treasure version. Let's summarize the upgrade:
              </p>
              <ul>
                <li>
                  Gain 1 stack of S2 every 100 shots --&gt; now happens in 70
                  Shots
                </li>
                <li>
                  S2 now gives pierce damage boost whenever she refunds ammo.
                </li>
              </ul>
              <p>
                If Crown gives her team a long reload buff which basically
                reduces reloading time as much as she can, then Diesel does
                kinda the same, except she "reloads" your magazine whenever she
                finishes her S2 (700 shots in total).
              </p>
              <p>
                A max S2 will refund 86.62% ammo of all allies. This is not
                really much if you are using other weapons, but for MG DPS, this
                is actually a pretty decent number. If we assume Diesel having
                at least 808 ammo, she will never reload, and if your DPS MG
                have that much ammo, neither do Modernia, Guillotine, xLudmilla
                as long as they are paired with Diesel. Since she never reloads,
                she will trigger her S2 every 11.67s, which is
              </p>
              <ul>
                <li>17 shot of SGs (25 with Tove)</li>
                <li>
                  8 fully-charged shots of 1s charge time RLs or SRs, which
                  means
                </li>
                <ul>
                  <li>
                    If your SG has 20 ammo (29 with Tove), you will never
                    reload.
                  </li>
                  <li>SR = RL = 10 ammo.</li>
                  <li>
                    For AR and SMG, they require quite high ammo and their base
                    uptime is good enough, you don't even need to worry about
                    reloading from the very start. (except Scarlet, but Scarlet
                    won't be able to make the most out of Diesel buff anyways).
                  </li>
                </ul>
              </ul>
              <p>
                We all know Pierce damage boost belongs to Attack Damage group
                (Crown burst buff), except it only works on the damage having
                pierce effect (golden text right below number). Since her value
                is triple bigger than D:KW's S1, and doesn't require specific
                weapon, Diesel can support the following important DPS units:
              </p>
              <ul>
                <li>Red Hood and Alice</li>
                <li>Laplace (her burst does constant Pierce damage)</li>
                <li>
                  Snow White and Maxwell (their bursts are Pierce damage nukes)
                </li>
              </ul>
              <Alert variant="primary">
                <p>
                  The uptime of this buff is not 100% at this phase, because it
                  only lasts 10s, but the "cool-down", at lowest, is 11.67s.
                </p>
              </Alert>
              <p>So with her Phase 1 Favorite, we have a fake Crown who</p>
              <ul>
                <li>Mostly buffs MG to never reload</li>
                <li>Mostly buffs Piercers as Attack Damage buff.</li>
              </ul>
              <br></br>
              <h5>Upgrade Burst [Phase 2]</h5>
              <StaticImage
                src="../../../images/nikke/reviews/diesel_tr_2.webp"
                alt="Kit"
              />
              <p>
                The change in Phase 2 applies to her Burst. This change is
                so-so, because you gain something but you lose something.
              </p>
              <ul>
                <li>
                  Taunt changes from a debuff on enemies to Attract buff on self
                  (they behave the same, but Taunt has higher priority than
                  Attract if enemy is under 2 types of taunting, and Taunt
                  doesn't "reapply" when the wave is cleared).
                </li>
                <li>
                  Attract duration changes to fix 10s (This is very good for her
                  tanking ability synergy).
                </li>
                <li>Increases her max HP (without healing her).</li>
              </ul>
              <p>
                Old Diesel needed burst lv 10 to taunt enemies for 5s. Treasure
                Diesel just casually provokes everything for 10s even at lv 1.
                Moreover, during taunt, Diesel can heal herself a lot due to her
                S1.
              </p>
              <p>
                The HP buff seems random, until you look at her S1. Because her
                S1 will heal herself based on her own max HP, increasing her max
                HP will also increase that value by a huge margin. It's so huge
                that Diesel can even shoot Scarlet to trigger Scarlet's reflect
                and refill Diesel's HP bar to 100% through the entire Full Burst
                (this action will kill all other MG, avoid Scarlet if you are
                running a MG in PVP)
              </p>
              <Alert variant="primary">
                <p>
                  Another fake version of Crown kit, where she is technically
                  immune while taunting. Problem is, Diesel won't be able to
                  protect your team from AoE skills, and cannot protect you or
                  herself from one-shot attacks.
                </p>
              </Alert>
              <br></br>
              <h5>Upgrade S1 [Phase 3]</h5>
              <StaticImage
                src="../../../images/nikke/reviews/diesel_tr_3.webp"
                alt="Kit"
              />
              <p>
                The most expensive phase, but with a very weak change. Mostly
                you would want to move on other Treasure units until you finish
                them, then go back and finish Diesel.
              </p>
              <ul>
                <li>
                  S1 heals Diesel during Full Burst --&gt; During Diesel's burst
                </li>
                <li>Increase S2's stack by 1 every 150 shots.</li>
              </ul>
              <p>
                The first change might hurt or won't hurt, since old Diesel just
                heals herself whenever it's Full Burst, doesn't matter if she
                bursts or not. Treasure version requires Diesel to burst, and
                because her burst is not particularly strong, bursting Diesel
                just to heal seems kinda wasted. (To be fair, this is quite a
                stretch because without bursting, Diesel normally can't taunt
                enemy to attack her --&gt; no heal at all. In some niche cases,
                she can)
              </p>
              <p>
                The second upgrade is interesting, because whenever she bursts,
                she will increase her S2 stacks faster. Which means,
              </p>
              <ul>
                <li>
                  The requirement to trigger S2 is lowered from 700 to 490
                  shots. (which is 8.17s)
                </li>
                <li>
                  After the S2 is cleared, she should still be able to generate
                  1 more stack before burst end assuming you never stop
                  shooting. Due to this upgrade, the requirement for Diesel and
                  other MG is lowered to 566 ammo, which equals 88% ammo
                  increased. It's roughly 2 OL gear ammo lines. And it also
                  helps increasing the consistency of S2's Pierce damage boost
                  (10s duration vs 8.17s cooldown)
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <h5>
                Campaign (LD) - <strong className="s">S</strong> | Campaign (HD)
                - <strong className="a">A</strong>
              </h5>
              <p>
                Diesel can buff the best campaign units (Red Hood, Alice,
                Maxwell, and even Snow White in niche stages) substantially.
                While she is not as good as Crown, she can compete with most of
                the B2 roster. There are some problems that are associated with
                her. When you haven't unlocked OL gear, having Diesel to reduce
                reload time sounds like a fun concept, but when you have more
                ammo, she doesn't look that great anymore, and is only used for
                her Pierce Damage buff. Taunting with Treasure Diesel in
                campaign is okay on some niche situations, but on most High
                Deficit stages, it's impossible since all mobs instantly
                one-shot you. Sacrificing is a valid strategy, but tanking
                damage and surviving? Only Crown can do that effectively.
              </p>
              <h5>
                Boss (Solo) - <strong className="s">S</strong> | Boss (Adds) -
                <strong className="s">S</strong>
              </h5>
              <p>
                Most boss stages won't one-shot you, so taunting is actually a
                legit skill here. Problem is, her other values don't seem too
                appealing unless you are running other MGs, or using piercers
                like Alice, Red Hood, Laplace or Snow White.
              </p>
              <h5>
                PVP - <strong className="b">B</strong>
              </h5>
              <p>
                Her changes mostly affect PVE gameplay. For PVP, she sounds
                super legit on paper. I mean, sure Scarlet can't kill Diesel,
                but she can nuke other 4 due to the lack of an AoE block, and
                then kill Diesel later because she doesn't have any protection
                when she's not in Full Burst.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <div className="nikke-skills">
                <div className="nikke-skills-row over header">
                  <div className="column character">Character</div>
                  <div className="column suggestions essential">Essential</div>
                  <div className="column suggestions ideal">Ideal</div>
                  <div className="column suggestions passable">Passable</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-row">
                  <div className="nikke-skills-row over data">
                    <div className="column character">
                      <NikkeCharacter
                        slug="diesel"
                        mode="icon-tier"
                        showLabel
                        enablePopover
                        onlyName
                        tierID={25}
                      />
                    </div>
                    <div className="column suggestions pve">
                      <p className="on-mobile">Essential</p>
                      <p>-</p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Ideal</p>
                      <p>
                        <strong>2~3x Max Ammo</strong>
                      </p>
                    </div>
                    <div className="column suggestions pvp">
                      <p className="on-mobile">Passable</p>
                      <p>-</p>
                    </div>
                    <div className="column priority">
                      <p className="on-mobile">Priority</p>
                      <p className={`prio prio-5`}>Medium (PvE)</p>
                    </div>
                  </div>
                  <div className={`explanation`}>
                    <p className="on-mobile">Notes</p>
                    <p>
                      Diesel only requires Max Ammo, the value of which depends
                      on your Treasure's Phase. The amount of ammo will vary
                      from 700 to 490, which is 133% ~ 88% ammo up. If we
                      consider mid-value Ammo lines, with the help of a lvl7
                      Bastion cube, Diesel's ideal OL gear will need 2-3 Ammo.
                    </p>
                  </div>
                </div>
              </div>
              <br></br>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 1~4</strong>
                </li>
                <ul>
                  <li>
                    Low impact, skill effects are fixed or too strong even at
                    base level.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 7~10</strong>
                </li>
                <ul>
                  <li>
                    The higher S2's value, the more ammo she refunds and Pierce
                    Damage Boost. Upgrade this for better team buff.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 4~7</strong>
                </li>
                <ul>
                  <li>
                    Low impact, skill effects are fixed or too strong even at
                    low level.
                  </li>
                </ul>
              </ul>
              <br></br>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="diesel" />
                </div>
              </div>
              <br />
              <br></br>
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                MGs love the bastion cube! If you haven't reached 700+ Ammo,
                slap Bastion on her. But, depending on your Favorite Item
                Progression and Ammo lines (700+ Ammo in Phase 1/2), she will
                never reload so you can slap Vigor cube on her (Max HP up) to
                make her survive better during taunting.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The Pierce Damage Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="diesel"
                      enablePopover
                      tierID={25}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter slug="alice" enablePopover mode="icon" />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Flex</div>
                </div>
              </div>
              <p>
                This team is built around the Pierce Damage Boost of Diesel for
                Piercers. Not much to say, you pick 2 DPS, and have 1 slot for
                either heal against AoE damage, or more buffing. Can be even CDR
                if your B1 is another buffer/healer.
              </p>
              <h5>Team #2: The Snow White Team!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="miranda" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="diesel"
                      enablePopover
                      tierID={25}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="aqua-marine-helm"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="snow-white"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="yulha" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team is a variation of the traditional Snow White team,
                with Helm is replaced by Diesel. It should buff Snow White
                better than Helm, in exchange for lower survivability against
                AoE. Bosses like Grave Digger (Solo Raid season 14) are a good
                example of where Diesel would've been better. But Storm Bringer
                (Solo Raid season 11) is not a good example of putting Diesel in
                this team.
              </p>
              <h5>Team #3: Ingrid's Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="d-killer-wife"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon-tier"
                      slug="diesel"
                      enablePopover
                      tierID={25}
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="guillotine"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      slug="privaty-unkind-maid"
                      enablePopover
                      mode="icon"
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="privaty" enablePopover />
                    <NikkeCharacter mode="icon" slug="vesti" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Yes, it's also called Elysion's tower team. Your Guillotine 1 HP
                is now even safer due to Diesel's protection (Until Bronchus
                blows her apart). The only problem is: Where's the burst gen?
                Where's the pierce DPS? (Ask Ingrid for them). With 2x ammo
                lines on both Diesel and Guillotine, they won't reload so you
                don't have to rely on Privaty anymore for reload buff, and can
                safely put Vesti/Helm in for some copium burst gen. This team is
                especially good for Bosses in Elysion Tower.
              </p>
              <div className="section-scroll" id="section-5">
                <SectionHeader title="PvP Team Compositions" />
                <h5>3 RL Hidden Damage Team</h5>
                <div className="nikke-team-showcase">
                  <div className="team-slot">
                    <h5 className="burst-1">Burst 1</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon"
                        slug="red-hood"
                        enablePopover
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-2">Burst 2</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon-tier"
                        slug="diesel"
                        enablePopover
                        tierID={25}
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-3">Burst 3</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-3">Burst 3</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter
                        mode="icon"
                        slug="soda-twinkling-bunny"
                        enablePopover
                      />
                    </div>
                  </div>
                  <div className="team-slot">
                    <h5 className="burst-flex">Burst Flex</h5>
                    <div className="employee-container for-nikke">
                      <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                      <NikkeCharacter mode="icon" slug="sugar" enablePopover />
                    </div>
                  </div>
                </div>
                <p>
                  This team has a few ways to distribute its threat. We have Red
                  burst to kill P1, forcing enemy to put Defender P1 + Biscuit
                  to answer. But we also have B.Soda + either Pepper or Sugar to
                  threaten P5. And not to mention Emilia can damage both P1, P2
                  and even P3 with her Auto Attack + Red buff. If you use
                  Pepper, this is the case when you want a protection in case
                  Red Hood is sniped (by Rosanna for example). Sugar is more
                  straight forward, more damage to P5.
                </p>
              </div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>High buff and taunt uptime.</li>
                        <li>
                          Good against stages (mostly Boss stages) where chip
                          damage is prevalent.
                        </li>
                        <li>
                          Gives ▲Pierce Damage, which is usually an undiluted
                          buff.
                        </li>
                        <li>
                          Can reduce reloading time overall for teammates by
                          refilling magazines.
                        </li>
                        <li>
                          Almost immortal during burst (she can only die to
                          one-shots).
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Must burst to be near immortal.</li>
                        <li>
                          Won't survive in High Deficit against high damage
                          Raptures.
                        </li>
                        <li>
                          Buffs are strong but very niche, and B2 competition is
                          kinda fierce right now.
                        </li>
                        <li>
                          Can't protect team well against AoE Wiping like
                          Scarlet's burst.
                        </li>
                        <li>
                          MGs are slow in generating burst gen, and feed burst
                          gen in return to opponent Jackal or Scarlet.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesDiesel;

export const Head: React.FC = () => (
  <Seo
    title="Diesel (Treasure) guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Diesel (Treasure) in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
